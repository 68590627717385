import React from 'react';

import LayoutEs from '../components/LayoutEs';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SEO from '../components/Seo';
import Conectamos from '../components/Contacto/Conectamos';
import Escuchamos from '../components/Contacto/Escuchamos';
import Projects from '../components/Proyectos';
import ScrollToTop from '../components/ScrollToTop';

class Contacto extends React.Component {
  render() {
    return (
      <LayoutEs>
        <Header />
        <SEO
          title="Contacta con Beköme Digital"
          description="Bekome Digital es una agencia espezializada en ecommerce que ofrece desarrollo Magento 2 en Barcelona y España"
          canonicalUrl="https://www.bekome.digital/contacto/"
        />

        <header className="masthead secondary contacto d-flex">
          <div className="container text-center my-auto">
            <h1 className="mb-1 text-primary">¿Qué podemos hacer por ti?</h1>
          </div>
          <div className="overlay"></div>
        </header>

        <Conectamos />

        <Escuchamos />

        {/*<section id="contact" className="map">
            <iframe
                width="100%"
                height="100%"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                loading="lazy"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2985.2971533711057!2d2.0119349158302535!3d41.5628129792484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a492e9c3879879%3A0x91539353f442e85e!2sCarrer%20de%20la%20Font%20Vella%2C%2078%2C%2008221%20Terrassa%2C%20Barcelona!5e0!3m2!1ses!2ses!4v1644482963364!5m2!1ses!2ses"
            ></iframe>
            <br />
            <small>
                <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2985.2971533711057!2d2.0119349158302535!3d41.5628129792484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a492e9c3879879%3A0x91539353f442e85e!2sCarrer%20de%20la%20Font%20Vella%2C%2078%2C%2008221%20Terrassa%2C%20Barcelona!5e0!3m2!1ses!2ses!4v1644482963364!5m2!1ses!2ses"></a>
            </small>
        </section>*/}

        <Projects />

        <Footer />

        <ScrollToTop />
        
      </LayoutEs>
    );
  }
}

export default Contacto;

import React from 'react';
import Contact from '../Contact';

export default function Escuchamos() {
  return (
    <section className="intro-section bg-grey mb-5" id="start">
      <div className="container content-section">
        <div className="row">
          <div className="col-12 col-lg-6 order-1 order-lg-0 mt-2">
            <Contact />
          </div>
          <div className='col-12 col-lg-6 order-0 order-lg-1'>
            <h2 className="font-size__xl mb-5 text-secondary text-center">
                Te escuchamos
            </h2>
            <p className='font-size__m'>
              Si tienes alguna duda, comentario o, simplemente, quieres contactar con nosotros puedes 
              hacerlo a través de este formulario o escribiendo un email a hola@bekome.digital
            </p>
            <p className='font-size__m'>
              Haremos todo lo posible por contestar en menos de 24h.
            </p>
            <p className='font-size__m'>
              Y, si necesitas una respuesta inmediata, llámanos al +34 694 454 907 (lun-vie de 9h a 17h) y te atenderemos 
              al momento.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

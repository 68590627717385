import React from 'react';
import { PopupButton } from 'react-calendly';

export default function Conectamos() {
  return (
    <section className="intro-section" id="start">
      <div className="container content-section px-4">
        <div className="row">
          <div className="col-12 col-md-6">
            <h2 className="font-size__xl mb-5 text-secondary">
              ¿Conectamos?
            </h2>
            <p className='font-size__m'>
              Si estás aquí es porque crees que podemos ayudarte.
            </p>
            <p className='font-size__m'>
              ¿Te vas a quedar con la duda?
            </p>
            <p className='font-size__m mb-5'>
              Reserva una consultoría gratuita de 15 min y descubre todo lo que Bekome puede hacer por ti
            </p>
            <PopupButton text="Elige fecha aquí" className='btn btn-primary-green btn-lg mb-5' url="https://calendly.com/bekome-digital/consultoria-gratuita-30m" />
          </div>
          <div className='col-12 col-md-6 hidden-xs'>
          <img
            src="https://res.cloudinary.com/drjo0cbtf/image/upload/c_scale,f_auto,q_auto:best,w_700/v1655827181/bekome/baixa_006_lh33kd.jpg"
            alt="Manel Lavela Experto Ecommerce"
            className="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
